form {
    .info {
        width: 24px;
        height: 24px;
        margin: 0 6px;
    }

    .form-group {
        padding: 10px;
        position: relative;

        &.checkbox {
            display: flex;
            align-items: center;

            label {
                margin: 0;
            }
            ion-checkbox {
                margin: 0 5px 0 0;
            }
        }
    }

    label {
        display: block;
        font-weight: bold;
        font-size: 0.9em;
        margin: 0 0 5px;
    }

    ion-input,
    ion-textarea,
    ion-select,
    input[type='text'] {
        --padding-start: 8px !important;
        --padding-start: unset;
        --padding-top: 10px !important;
        --padding-bottom: 10px !important;
        min-width: 200px;
        border: 1px solid #e0e0e0;
    }

    input[type='text'] {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--padding-start);
        padding-inline-start: var(--padding-start);
        -webkit-padding-end: var(--padding-end);
        padding-inline-end: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        outline: none;
        display: block;
    }

    ion-textarea {
        min-height: 100px;
    }

    .horizontal {
        display: flex !important;
        flex-direction: row !important;
        overflow: hidden;

        ion-input,
        ion-textarea,
        ion-select,
        input[type='text'] {
            min-width: auto;
        }

        > * {
            flex: 1 1 auto;
            overflow: hidden;
        }
    }

    .box {
        display: block;
        border: 1px solid #e0e0e0;
        margin: 8px 10px;
    }

    .box,
    .section,
    .group {
        padding-top: 8px;
        h2,
        label,
        p {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    .tags {
        > span {
            display: inline-block;
            background: #ccc;
            border-radius: 8px;
            padding: 5px 8px;
            cursor: default;
            margin: 0 4px 4px 0;
        }

        .removeTag {
            margin-left: 5px;
            cursor: pointer;
            padding: 5px;
        }
    }

    .floatingButtons {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
        height: 60px;
        line-height: 50px;
        border-top: 1px solid #e0e0e0;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }
}

ion-select-popover,
ion-select-popover ion-list,
.popover-wrapper,
.popover-content,
ion-popover {
    min-width: 320px !important;
    --min-width: 320px;
}

.popover-wide {
    --max-width: 80vw;
    --width: auto;
    .alert-wrapper {
        // flex-grow: 1;
    }

    .alert-tappable.sc-ion-alert-md {
        contain: content;
    }
}
