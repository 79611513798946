@font-face {
    font-family: Bodoni;
    src: url('/assets/blood-red-skies/fonts/unicode.bodonieb.ttf');
}

abs-page-layout > .blood-red-skies {
    background: url(/assets/blood-red-skies/images/bg.jpg) top left repeat;
    background-size: cover;
}

.blood-red-skies {
    * {
        font-family:
            Futura,
            -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            'Open Sans',
            'Helvetica Neue',
            sans-serif;
    }
    $darkgreen: #32463e;
    $darkgreencontrast: #e3d6b8;
    $palegreen: #689472;
    $contrasttext: #685e49;

    --ion-color-primary: #{$darkgreen};
    --ion-color-primary-rgb: 68, 94, 72;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: $darkgreen;
    --ion-color-primary-tint: $palegreen;
    --ion-toolbar-color: $darkgreencontrast;

    ion-header {
        background: $palegreen;
        color: $darkgreencontrast;
        .title {
            font-family: Bodoni;
        }

        .subtitle {
            font-size: 0.8em;
        }
    }

    ion-item {
        --background: none;
        border-color: $palegreen;
    }

    ion-content {
        .wrap {
            > abs-list {
                padding-left: var(--gutter);
                padding-right: var(--gutter);
            }
        }
    }

    ion-list {
        background: none !important;
        ion-item {
            border-color: $palegreen;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            --background: none;

            &.alt {
                --background: none;
            }
        }

        ion-item-divider {
            background: none;
        }
    }

    h2 {
        background: $palegreen;
        font-family: Bodoni;
        color: black;
        padding: 10px 40px 10px 70px !important;
        margin-left: -50px;
        clear: both;
        display: inline-block;
    }

    .flag {
        border: 0 !important;
    }
}
