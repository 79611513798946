@font-face {
    font-family: 'IM Fell Great Primer';
    src: url('/assets/black-seas/fonts/IMFellGreatPrimer-Regular.ttf');
}

.black-seas {
    abs-page-layout {
        background: url(/assets/black-seas/images/bg.jpg) top left no-repeat;
        background-size: cover;
    }

    $red: #5c1719;
    --ion-color-primary: #7c2b38;
    --ion-color-primary-rgb: 124, 43, 56;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #5c1719;
    --ion-color-primary-tint: #ae3f42;
    * {
        font-family: 'IM Fell Great Primer', serif;
    }

    button,
    ion-button {
        text-transform: uppercase;

        color: #fff;
        --button-bg: var(--ion-color-primary) !important;

        border: 0;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 3px;
        --border-radius: 0;
        &:hover {
            color: #fff;
            // --button-bg: #ccc; //darken(var(--ion-color-primary), 20%) !important;
        }
    }

    ion-list {
        --ion-item-background: none;
    }

    ion-toolbar {
        --color: white;
        --background: url(/assets/black-seas/images/bg-header.png) bottom center repeat-x;
        background: url(/assets/black-seas/images/bg-header.png) bottom center repeat-x;
    }

    ion-header:after {
        display: none;
    }

    .subtitle {
        font-size: 0.8em;
    }

    ion-content,
    ion-footer {
        --background: url(/assets/black-seas/images/bg.jpg) fixed center/cover no-repeat;
        background: url(/assets/black-seas/images/bg.jpg) fixed center/cover no-repeat;
    }

    .itemName {
        min-width: 0;
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    ion-item-divider {
        margin-top: 10px;
        font-weight: bold;
    }

    .content {
        // margin: 10px;
    }

    ion-item {
        --inner-border-width: 0;
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        --background: url(/assets/black-seas/images/bg.jpg) top center/cover no-repeat fixed;

        &.alt {
            --background: linear-gradient(rgba(235, 219, 204, 0.5), rgba(235, 219, 204, 0.5)),
                url(/assets/black-seas/images/bg.jpg) top center/cover no-repeat fixed;
        }

        &.active {
            --background: linear-gradient(rgba(124, 43, 56, 0.9), rgba(124, 43, 56, 0.9)),
                url(/assets/black-seas/images/bg.jpg) top center/cover no-repeat fixed;
            color: #fff;
        }

        .itemWrap {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
            width: 100%;
        }

        .itemName {
            flex: 1 0 auto;
            white-space: nowrap;
            padding: 0;
        }

        .itemCost {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding: 0;
        }

        .itemDescription {
            flex: 1 0 100%;
            padding-top: 10px;
        }
    }

    ion-item-sliding:last-child ion-item {
        // --border-width: 1px 0 1px 0 !important;
    }

    ion-item-divider {
        --background: none;
        background: none;
    }

    .flex {
        display: flex;
    }

    abs-status-indicators {
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
    }
    abs-status-indicator.flag {
        margin: auto 10px auto 0;
    }
}
