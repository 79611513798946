@use 'sass:color';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@font-face {
    font-family: IBMPlexSansCondensed;
    src: url('/assets/bolt-action/fonts/IBMPlexSansCondensed-Bold.ttf');
}

@font-face {
    font-family: Nidex;
    src: url('/assets/bolt-action/fonts/Nidex.ttf');
}

@font-face {
    font-family: Univers;
    src: url('/assets/bolt-action/fonts/Univers LT 67 Bold Condensed.ttf');
}

.bolt-action {
    $primary: #517714;
    $primary-rgb: 81, 119, 20;
    --ion-color-primary: #{$primary} !important;
    --ion-color-primary-rgb: #{$primary-rgb};
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #{color.scale($primary, $lightness: -20%)};
    --ion-color-primary-tint: #{color.scale($primary, $lightness: 20%)};

    background: #fff url(/assets/bolt-action/images/header.jpg) top left/auto 213px repeat-x;
    background-position-y: calc(-98px + var(--ion-safe-area-top, 0));

    &.no-background {
        background: none;
    }

    button,
    ion-button {
        font-family: Univers;
        text-transform: uppercase;

        color: #fff;
        --button-bg: var(--ion-color-primary) !important;

        border: 0;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 3px;
        --border-radius: 0;
        &:hover {
            color: #fff;
            // --button-bg: #ccc; //darken(var(--ion-color-primary), 20%) !important;
        }
        &.button-outline {
            color: var(--ion-color-primary);
        }
    }

    ion-header {
        ion-toolbar {
            color: white;
            font-family: Nidex;
            font-size: 0.9em;
        }
        .title {
            text-transform: uppercase;
        }

        .subtitle {
            font-size: 0.85em;
        }
    }

    h1,
    h2,
    h3,
    th,
    ion-card-title {
        font-family: Univers;
        text-transform: uppercase;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 21px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 15px;
    }

    abs-list {
        ion-item {
            $bg: #eed;
            background: $bg;
            --background: $bg;

            &.alt {
                $bg: rgb(253, 250, 243);
                background: $bg;
                --background: $bg;
            }

            &.active {
                $bg: var(--ion-color-primary);
                color: #fff;
                background: $bg;
                --background: $bg;
            }
        }
    }

    // Duplicated here because in some places (e.g. PDF export modal) we don't use the abs-list component, which adds the .alt class for us due to the way rows are rendered
    // ion-list {
    ion-item {
        &:nth-child(odd) {
            $bg: #eed;
            background: $bg;
            --background: $bg;
        }

        &:nth-child(even) {
            $bg: rgb(253, 250, 243);
            background: $bg;
            --background: $bg;
        }

        &.active {
            $bg: var(--ion-color-primary);
            color: #fff;
            background: $bg;
            --background: $bg;
        }
    }
    // }

    .disabled {
        text-decoration: line-through;
        color: #999;
    }

    wl-flag {
        border: 1px solid #666;
    }

    &.specialRules {
        background: none;
        table {
            td,
            th {
                &:first-child {
                    width: 10%;
                    text-align: center;
                }
                padding: 5px;
            }
        }
    }

    table {
        /* margin: 0 10px; */
        width: 100%;
    }
    td,
    th {
        padding: 5px 10px;
        text-align: left;
        border: 1px solid #000;
        border-collapse: collapse;
    }

    table th {
        font-weight: bold;
        background: rgb(253, 250, 243);
    }
}

.unitSummaryStats {
    p {
        margin-bottom: 0 !important;
    }

    ul {
        margin-top: 0;
    }
}
