@use 'sass:color';
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import './admin.scss';

wl-home {
    * {
        font-family: var(--ion-font-family, inherit) !important;
    }
}

@font-face {
    font-family: Poppins;
    src: url(/assets/fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: Stencil;
    src: url(/assets/fonts/stencil.ttf);
}

.home {
    ion-button {
        --ion-font-family: Poppins, Arial;
        font-weight: bold;
        color: #000000;
        --background: var(--button-bg) !important;
        --button-bg: linear-gradient(0deg, rgba(255, 236, 100, 1), rgba(255, 171, 45, 1) 96%) !important;
        border: 1px solid #ffab2d;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 3px;
        min-height: 46px;
        --border-radius: 0;
        &:hover {
            color: #000000;
            --button-bg: linear-gradient(180deg, rgba(255, 236, 100, 1), rgba(255, 171, 45, 1) 100%) !important;
            border-color: #ffab2d;
        }
    }
}

body,
.home {
    --gutter: 15px;

    $primary: #000000;
    $primary-rgb: 0, 0, 0;
    --ion-color-primary: #{$primary};
    --ion-color-primary-rgb: #{$primary-rgb};
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #{color.scale($primary, $lightness: -20%)};
    --ion-color-primary-tint: #{color.scale($primary, $lightness: 20%)};

    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;

    ul,
    ol {
        margin-block-start: 0.8em;
        margin-block-end: 0.8em;
        padding-inline-start: 40px;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: Poppins;
    }

    ion-menu {
        ion-footer {
            &:before {
                background: none !important;
            }
            text-align: center;
            font-size: 0.7em;
            img {
                display: block;
                width: 50%;
                margin: 10px auto;
            }
            padding-bottom: calc(5px + var(--ion-safe-area-bottom, 0)) !important;
        }
    }

    .error,
    .warning {
        margin: 10px;
        background: rgba(255, 160, 170, 1);
        border: 1px solid rgba(255, 100, 100, 1);
        padding: 10px;
        border-radius: 5px;
    }

    .warning {
        background: rgba(255, 200, 130, 1);
        border: 1px solid rgba(200, 150, 30, 1);
    }

    ion-modal {
        --background: none !important;
        --box-shadow: none !important;

        --height: 100%;
        --width: 100%;
    }

    .modal-wrapper {
        box-shadow: none !important;
    }

    .card-content-md {
        p {
            margin-bottom: 0.8em;
        }
    }

    abs-page-layout {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    ion-header {
        ion-toolbar {
            --ion-toolbar-background: none;
        }
    }

    ion-content {
        --background: none;
        background: none;
        overflow: hidden;

        .wrap {
            > p,
            > h2,
            > .headerWrap > h2 {
                padding-left: var(--gutter);
                padding-right: var(--gutter);
            }
        }
    }

    .buttons {
        padding: var(--gutter) calc(var(--gutter) - 2px) 0;
        display: flex;
        align-items: center;

        ion-button {
            flex: 1 1 100%;
        }

        ion-button.small {
            flex: 0 0 auto;
        }

        & + .buttons {
            padding-top: 0;
        }

        &.vertical {
            flex-direction: column;
            ion-button {
                width: 100%;
            }
        }
    }

    .content .buttons {
        padding-left: 0;
        padding-right: 0;
    }

    ion-button {
        ion-spinner {
            margin-left: 10px;
            height: 16px;
            width: 16px;
        }
    }

    .itemWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .itemDescription {
            flex: 1 0 100%;
            padding-bottom: 15px;
        }
    }

    .itemName {
        min-width: 0;
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        padding: 15px 0;
        > span {
            text-overflow: ellipsis;
            // white-space: nowrap;
            overflow: hidden;
        }
    }

    .itemCost {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    .itemDescription {
        width: 100%;
        font-size: 0.9em;
        font-style: italic;
        opacity: 0.8;
    }

    .content {
        padding: 0 var(--gutter);
    }

    ion-item-divider {
        padding: 0 var(--gutter);
        display: flex;
    }

    .alert-horizontal-buttons {
        .alert-button-group {
            flex-direction: row;
        }
    }

    .alert-number-picker {
        .alert-wrapper {
            // width: 300px;
            min-width: 250px;
        }
    }

    ion-list {
        padding-top: 1px;
        --ion-background-color: none;

        .row:first-child > ion-item-sliding > ion-item,
        .row:first-child > ion-item {
            border-width: 1px !important;
        }

        ion-item {
            --inner-border-width: 0;
            --border-width: 0 !important;
            border-width: 0px 1px 1px 1px !important;
            border-color: #cdcdcd;
            border-style: solid;
            justify-content: center;
            display: flex;
            flex-direction: column;

            wl-info {
                ion-icon {
                    height: 32px;
                    width: 32px;
                }
                float: right;
            }
            .validationError {
                margin-right: 5px;
            }

            .previewIcon {
                float: right;
                position: relative;
                z-index: 2;
            }

            // &.active {
            //     $bg: rgba(var(--ion-color-primary-rgb), 0.1);
            //     background: $bg;
            //     --background: $bg;
            //     // color: #fff;

            //     &.alt {
            //         $bg: rgba(var(--ion-color-primary-rgb), 0.2);
            //         background: $bg;
            //         --background: $bg;
            //     }
            // }
        }

        > ion-item:first-child,
        ion-item.first {
            border-width: 1px !important;
        }
    }

    .previewIcon {
        font-size: 2.5em;
        &:hover {
            color: #555;
            cursor: pointer;
        }
    }
}

.modal-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.itemCost + wl-preview-icon {
    padding-left: 10px;
}

ion-input,
ion-textarea,
ion-select,
input[type='text'] {
    --padding-start: 8px !important;
    --padding-start: unset;
    --padding-top: 10px !important;
    --padding-bottom: 10px !important;
    min-width: 200px;
    border: 1px solid #e0e0e0;
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    overflow-y: auto;
}

.floating {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    /* width: 600px;
    left: 50%;
    margin-left: -300px; */
    text-align: center;
    padding: 0 !important;
}

.floating ion-button {
    width: 100%;
    margin: 0;
    height: 50px;
    --border-radius: 0;
}
