/* 
    Put here any styles that should be applied across all apps to reduce duplication
*/

ion-item {
    --inner-border-width: 0;
    overflow: visible;
}

ion-list {
    // ion-item {
    //     --inner-border-width: 0;
    //     --padding-start: 16px;
    //     --padding-end: 16px;
    // }

    ion-item-option,
    .itemIcons ion-icon {
        padding: 12px 0;
        &:hover {
            opacity: 0.5;
        }
    }

    ion-item-options {
        background: rgb(219, 69, 69);
    }

    .itemOptions {
        ::part(native) {
            -webkit-padding-start: 0;
            padding-inline-start: 0;
            -webkit-padding-end: 0;
            padding-inline-end: 0;
        }
        ion-item-option {
            --background: revert;
            --color: revert;
        }
    }

    .delete {
        background: rgb(219, 69, 69);
    }

    ion-item-option ion-icon,
    .itemIcons ion-icon {
        width: 1.2em;
        height: 1.2em;
        font-size: 16px !important;
    }
}

.error,
.warning {
    margin: 10px;
    background: rgba(255, 160, 170, 1);
    border: 1px solid rgba(255, 100, 100, 1);
    padding: 10px;
    border-radius: 5px;
}

.warning {
    background: rgba(255, 200, 130, 1);
    border: 1px solid rgba(200, 150, 30, 1);
}

ion-content {
    --offset-top: 56px !important;
    --offset-bottom: -56px !important;
}

body ion-menu ion-content {
    --offset-top: 0 !important;
    --offset-bottom: 0 !important;
    overflow: auto;
}

.wrap {
    max-width: var(--wrapWidth, 600px);
    margin: 0 auto;
    padding-bottom: calc(var(--ion-safe-area-bottom, 0) + var(--offset-top));
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.rotating {
    animation: rotation 2s infinite linear;
}

ion-button.fullWidth {
    width: 100%;
}

.fullPageOverlay {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 1);
    align-items: center;
    justify-content: center;
}

.fullPageOverlay h1 {
    font-family: Nidex;
    font-size: 1.4em;
    text-transform: uppercase;
}

.fullPageOverlay ion-card {
    padding: 20px;
    text-align: center;
    max-width: 600px;
    max-height: 95vh;
    font-family: Univers;
    font-size: 1.2em;
    line-height: 1.6em;
    overflow-y: auto;
}

.iosOnly,
.webOnly,
.androidOnly {
    display: none;
}

.ios .iosOnly,
.web .webOnly,
.android .androidOnly {
    display: block;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.alignLeft {
    text-align: left;
}
